import React, { useState, useEffect, useRef } from "react";
import { Routes, Route } from "react-router-dom";
import { VStack } from "@chakra-ui/react";
import "focus-visible/dist/focus-visible";
import NET from "vanta/dist/vanta.net.min";
import Header from "./components/Header";
import Navbar from "./components/Navbar";
import Social from "./components/Social";
import Profile from "./components/Profile";
import "./App.css";

function App() {
  const [vantaEffect, setVantaEffect] = useState(0);
  const background = useRef(null);

  useEffect(() => {
    if (!vantaEffect) {
      setVantaEffect(
        NET({
          el: background.current,
          mouseControls: true,
          touchControls: true,
          gyroControls: false,
          minHeight: 200.0,
          minWidth: 200.0,
          scale: 1.0,
          scaleMobile: 2.0,
          color: 0xc31f57,
          backgroundColor: 0x130920,
          points: 10.0,
          maxDistance: 20.0,
          spacing: 20.0,
        })
      );
    }
    return () => {
      if (vantaEffect) vantaEffect.destroy();
    };
  }, [vantaEffect]);

  if (!localStorage["chakra-ui-color-mode"]) {
    localStorage.setItem("chakra-ui-color-mode", "dark");
  }

  return (
    <div className="darken" style={{ height: "100%" }}>
      <VStack>
        <div ref={background} className="back full"></div>
        <Navbar></Navbar>
        <Routes>
          <Route path="/" element={<Header />} />
          <Route path="contact" element={<Social />} />
          <Route path="work" element={<Profile />} />
        </Routes>
      </VStack>
    </div>
  );
}

export default App;
