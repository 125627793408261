var workArray = [
  {
    title: "FitPose",
    file: "fitpose-front.png",
    light: "https://i.ibb.co/Cs7TCj8/fitpose-front.png",
    icons: [
      { name: "SiDjango", color: "#218f65" },
      {
        name: "SiTensorflow",
        color: "#ff9100",
        variant: "solid",
      },
      { name: "SiOpencv", color: "" },
      { name: "SiPostgresql", color: "#6694ba" },
    ],
  },
  {
    title: "Tracr",
    file: "tracr-front.png",
    light: "https://i.ibb.co/85H02ct/tracr-front.png",
    icons: [
      { name: "SiDjango", color: "#218f65" },
      {
        name: "SiTensorflow",
        color: "#ff9100",
        variant: "solid",
      },
      { name: "SiOpencv", color: "" },
      { name: "SiHeroku", color: "#8e609c" },
    ],
  },
  {
    title: "ADM-FCRIT",
    file: "adm-fcrit-front.png",
    light: "https://i.ibb.co/6RhNkxZ/adm-fcrit-front.png",
    icons: [
      { name: "SiReact", color: "#61dafb" },
      { name: "SiNodedotjs", color: "lightgreen" },
      { name: "SiMaterialui", color: "#007fff" },
      { name: "SiMysql", color: "#80b3ff" },
    ],
  },
  {
    title: "MindAid",
    file: "mindaid-front.png",
    light: "https://i.ibb.co/ZKjh7kW/mindaid-front.png",
    icons: [
      { name: "SiReact", color: "#61dafb" },
      { name: "SiChakraui", color: "#5ECACA" },
      { name: "SiFirebase", color: "#FFCC30" },
    ],
  },
];

function myWorkArray() {
  return workArray;
}

export default myWorkArray;
