import React, { useEffect, useState } from "react";
import { Box, Flex, useMediaQuery } from "@chakra-ui/react";
import "../App.css";
import ProjectCard from "./ProjectCard";
import myWorkArray from "./workArray";

function Profile() {
  const [isNotSmallerScreen] = useMediaQuery("(min-width:1050px)");
  const [workArr, setWorkArr] = useState([]);

  useEffect(() => {
    const workArrayVariable = myWorkArray();
    setWorkArr(workArrayVariable);
  }, []);

  return (
    <div className="outer">
      <div className="middle">
        <div className="inner">
          <Flex
            flexDirection={isNotSmallerScreen ? "row" : "column"}
            justify="center"
            mb={isNotSmallerScreen ? "0" : "4"}
          >
            {workArr.length > 0 &&
              workArr.map((ele) => {
                return (
                  <Box mt={isNotSmallerScreen ? "0" : "2"}>
                    <ProjectCard
                      bgColorHover="rgba(208, 36, 93, 0.7)"
                      bgColorNonHover="rgba(208, 36, 93, 0.5)"
                      props={ele}
                    />
                  </Box>
                );
              })}
          </Flex>
        </div>
      </div>
    </div>
  );
}

export default Profile;
