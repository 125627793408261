var modalObj = {
  FitPose: {
    title:
      "A python based web app that helps users maintain their posture in real-time as they workout.",
    techArray: [
      "Django",
      "BlazePose (Mediapipe)",
      "OpenCV",
      "Chart.js",
      "PostgreSQL",
    ],
    linkedin:
      "https://www.linkedin.com/posts/bryanronad_python-opencv-mediapipe-activity-6804377697100328960-AHBp",
    github: "https://github.com/ABCSquad/FitPose-Django",
    hosting: "",
    youtube: "https://youtu.be/y2ljSTtsa2c",
  },
  Tracr: {
    title:
      "A math centric handwriting OCR powered app that can predict, parse and solve equations written by hand.",
    techArray: ["Django", "LeNet-5", "SymPy"],
    linkedin:
      "https://www.linkedin.com/posts/bryanronad_python-deeplearning-opencv-activity-6826246980847579136-26bd",
    github: "https://github.com/ABCSquad/tracr",
    hosting: "https://tracr.herokuapp.com/",
    youtube: "https://youtu.be/OdATbPRRVIc",
  },
  "ADM-FCRIT": {
    title:
      "A web application to digitize and analyze accreditation data received as forms and generate reports for an institution.",
    techArray: ["ReactJS", "Express/Node.js", "MaterialUI", "MySQL"],
    linkedin: "",
    github: "",
    hosting: "",
    youtube: "https://youtu.be/vDH6dDzOlbs",
  },
  MindAid: {
    title:
      "A mental health based web application that acts as a bridge between users and counsellors, giving them the ability to chat, schedule sessions based on availability and most importantly, find comfort and support.",
    techArray: ["ReactJS", "Chakra UI", "Firebase + Firestore"],
    linkedin: "",
    github: "https://github.com/BryanRonad/productathon",
    hosting: "",
    youtube: "https://youtu.be/FqYraeXwj7k",
    note: "Developed within 36 hours and secured 1st place in the Software Productathon hackathon (E-Summit 2022, IIT Roorke)",
  },
};

function myModalObj() {
  return modalObj;
}

export default myModalObj;
