import {
  Box,
  Flex,
  IconButton,
  Image,
  Spacer,
  Stack,
  Text,
  Tooltip,
  useMediaQuery,
} from "@chakra-ui/react";
import React, { useState } from "react";
// import * as Di from "react-icons/di";
import * as Si from "react-icons/si";
// import * as Gi from "react-icons/gi";
// import * as Fa from "react-icons/fa";
import ModalComponent from "./ModalComponent";

function ProjectCard({ bgColorHover, bgColorNonHover, props }) {
  const mdIcon = props.icons.map((icon) => {
    // if (Di[icon.name] !== undefined) {
    //   return Di[icon.name];
    // } else
    if (Si[icon.name] !== undefined) {
      return Si[icon.name];
      // } else if (Gi[icon.name] !== undefined) {
      //   return Gi[icon.name];
      // } else if (Fa[icon.name] !== undefined) {
      //   return Fa[icon.name];
    } else {
      return null;
    }
  });

  const [isNotSmallerScreen] = useMediaQuery(
    "(min-width:815px) and (min-height:860px)"
  );
  const [showModal, updateShowModal] = useState(false);

  const toggleModal = () => updateShowModal((state) => !state);

  return (
    <Flex
      className="group"
      rounded="xl"
      direction="column"
      bg={bgColorNonHover}
      justify="flex-start"
      minHeight="30vh"
      minWidth="30vh"
      ml={isNotSmallerScreen ? "6" : "4"}
      mr={isNotSmallerScreen ? "0" : "4"}
      mt={isNotSmallerScreen ? "0" : "2"}
      transition="all"
      transitionTimingFunction="ease-in-out"
      transitionDuration=".3s"
      _hover={{
        bg: bgColorHover,
        transform: "translate3d(0px, -5px, 0px)",
      }}
      onClick={toggleModal}
    >
      <Box p="4">
        <Image
          mt="2"
          boxShadow="dark-lg"
          htmlWidth="100%"
          borderStyle="inset"
          fit="cover"
          src={"/projects/" + props.file}
          alt={props.title + " Screen Capture"}
        />
      </Box>
      <Spacer />
      <Text color="white" ml="4" mb="2" fontSize="2xl" fontWeight="semibold">
        {props.title}
      </Text>

      <Stack direction="row" ml="4" mb="4">
        {mdIcon.map((icon, index) => (
          <Tooltip label={props.icons[index].name.substring(2)} openDelay={300}>
            <IconButton
              variant="solid"
              colorScheme="gray"
              color={props.icons[index].color}
              icon={React.createElement(icon)}
              isRound="true"
              size="md"
              fontSize="xl"
            />
          </Tooltip>
        ))}
      </Stack>
      <ModalComponent
        canShow={showModal}
        updateModalState={toggleModal}
        props={props}
      />
    </Flex>
  );
}

export default ProjectCard;
