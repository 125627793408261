import React from "react";
import theme from "../theme";
import { Flex, Link, Spacer, useMediaQuery } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";

function Navbar() {
  const [isNotSmallerScreen] = useMediaQuery("(min-width:815px)");
  return (
    <Flex w="100%" p={10} theme={theme}>
      <Spacer />
      <Link
        _hover={{ textDecor: "none" }}
        mr={isNotSmallerScreen ? "10" : "6"}
        color="white"
        href="/"
        fontSize={isNotSmallerScreen ? "22" : "15"}
      >
        <RouterLink to="/">About</RouterLink>
      </Link>
      <Link
        _hover={{ textDecor: "none" }}
        mr={isNotSmallerScreen ? "10" : "6"}
        color="white"
        href="#"
        fontSize={isNotSmallerScreen ? "22" : "15"}
      >
        <RouterLink to="/work">Work</RouterLink>
      </Link>
      <Link
        _hover={{ textDecor: "none" }}
        mr={isNotSmallerScreen ? "10" : "6"}
        color="white"
        href="/contact"
        fontSize={isNotSmallerScreen ? "22" : "15"}
      >
        <RouterLink to="/contact">Contact</RouterLink>
      </Link>
    </Flex>
  );
}

export default Navbar;
