import {
  chakra,
  Icon,
  Text,
  HStack,
  Link,
  Flex,
  useMediaQuery,
} from "@chakra-ui/react";
import React from "react";
import { FaGithub, FaInstagram, FaLinkedin, FaTwitter } from "react-icons/fa";
import { SiGmail } from "react-icons/si";
import "../App.css";

function Social() {
  const [isNotSmallerScreen] = useMediaQuery("(min-width:815px)");
  return (
    <div className="outer">
      <div className="middle">
        <div className="inner">
          <Flex minWidth="0" flexDirection="column" alignItems="center">
            <Text fontSize="large">Drop me a line at:</Text>
            <HStack mt="5">
              <Icon as={SiGmail} boxSize="30" />
              <Link
                fontSize={isNotSmallerScreen ? "x-large" : "large"}
                paddingLeft="15"
                href="#"
                _hover={{ textDecoration: "none" }}
                isExternal
              >
                <chakra.span color="#ed3f53" _hover={{ color: "#b81124" }}>
                  bryanronad
                </chakra.span>
                @gmail.com
              </Link>
            </HStack>
            <Text mt="20" fontSize="large">
              Other socials:
            </Text>
            <HStack mt="5">
              <Icon as={FaLinkedin} boxSize="30" />
              <Link
                fontSize={isNotSmallerScreen ? "x-large" : "large"}
                paddingLeft="15"
                href="https://www.linkedin.com/in/bryanronad/"
                _hover={{ textDecoration: "none" }}
                isExternal
                whiteSpace="nowrap"
              >
                linkedin.com/in/
                <chakra.span color="#ed3f53" _hover={{ color: "#b81124" }}>
                  bryanronad
                </chakra.span>
                /
              </Link>
            </HStack>
            <HStack mt="5">
              <Icon as={FaGithub} boxSize="30" />
              <Link
                fontSize={isNotSmallerScreen ? "x-large" : "large"}
                paddingLeft="15"
                href="https://github.com/BryanRonad"
                _hover={{ textDecoration: "none" }}
                isExternal
              >
                github.com/
                <chakra.span color="#ed3f53" _hover={{ color: "#b81124" }}>
                  BryanRonad
                </chakra.span>
                /
              </Link>
            </HStack>
            <HStack mt="5">
              <Icon as={FaInstagram} boxSize="30" />
              <Link
                fontSize={isNotSmallerScreen ? "x-large" : "large"}
                paddingLeft="15"
                href="https://www.instagram.com/bryan.ronad/"
                _hover={{ textDecoration: "none" }}
                isExternal
              >
                instagram.com/
                <chakra.span color="#ed3f53" _hover={{ color: "#b81124" }}>
                  bryan.ronad
                </chakra.span>
                /
              </Link>
            </HStack>
            <HStack mt="5">
              <Icon as={FaTwitter} boxSize="30" />
              <Link
                fontSize={isNotSmallerScreen ? "x-large" : "large"}
                paddingLeft="15"
                href="https://twitter.com/bryan_ronad"
                _hover={{ textDecoration: "none" }}
                isExternal
              >
                twitter.com/
                <chakra.span color="#ed3f53" _hover={{ color: "#b81124" }}>
                  bryan_ronad
                </chakra.span>
                /
              </Link>
            </HStack>
          </Flex>
        </div>
      </div>
    </div>
  );
}

export default Social;
