import {
  Box,
  Flex,
  IconButton,
  Spacer,
  Stack,
  Text,
  Tooltip,
  useMediaQuery,
} from "@chakra-ui/react";
import React from "react";
import { useColorMode } from "@chakra-ui/color-mode";
import { FaAngular, FaNodeJs, FaReact } from "react-icons/fa";
import { SiOpencv, SiTensorflow } from "react-icons/si";

function Header() {
  const [isNotSmallerScreen] = useMediaQuery(
    "(min-width:815px) and (min-height:860px)"
  );
  const { colorMode } = useColorMode();

  return (
    <Stack p={5}>
      <Flex
        direction={isNotSmallerScreen ? "row" : "column"}
        spacing="200px"
        p={isNotSmallerScreen ? "32" : "0"}
        alignSelf="flex-start"
      >
        <Box mt={isNotSmallerScreen ? "0" : "16"} align="flex-start">
          <Text
            fontSize={isNotSmallerScreen ? "4xl" : "3xl"}
            fontWeight="semibold"
            color="white"
          >
            Hi!
          </Text>
          <Text
            fontSize={isNotSmallerScreen ? "80" : "6xl"}
            fontWeight="bold"
            bgGradient="linear-gradient(150deg, #4300c7, #9400ae, #be0093, #d80079, #e70064, #ed3f53, #ed6449);"
            bgClip="text"
          >
            I'm Bryan Ronad
          </Text>
          <Text
            mt="4"
            fontSize={isNotSmallerScreen ? "18" : "15"}
            fontWeight="thin"
            color="white"
          >
            A third year undergraduate student studying Information Technology.{" "}
            <br /> I love doing full-stack web development with a touch of Deep
            Learning and Computer Vision.
          </Text>
        </Box>
      </Flex>
      <Flex pt="5">
        <Box display="flex" flexDirection="column" alignItems="center">
          <Tooltip label="NodeJS" isDisabled={isNotSmallerScreen}>
            <IconButton
              variant="solid"
              colorScheme={colorMode === "light" ? "gray" : "whiteAlpha"}
              color="lightgreen"
              icon={<FaNodeJs />}
              isRound="true"
              size="lg"
              fontSize="x-large"
            />
          </Tooltip>
          {isNotSmallerScreen ? (
            <Text mt="2" color="white">
              Node.js
            </Text>
          ) : null}
        </Box>
        <Spacer />
        <Box display="flex" flexDirection="column" alignItems="center">
          <Tooltip label="React" isDisabled={isNotSmallerScreen}>
            <IconButton
              variant="solid"
              colorScheme="gray"
              color="#61DAFB"
              icon={<FaReact />}
              isRound="true"
              size="lg"
              fontSize="x-large"
            />
          </Tooltip>
          {isNotSmallerScreen ? (
            <Text mt="2" color="white">
              ReactJS
            </Text>
          ) : null}
        </Box>
        <Spacer />
        <Box display="flex" flexDirection="column" alignItems="center">
          <Tooltip label="Angular" isDisabled={isNotSmallerScreen}>
            <IconButton
              variant="solid"
              colorScheme="gray"
              color="#C3002F"
              icon={<FaAngular />}
              isRound="true"
              size="lg"
              fontSize="x-large"
            />
          </Tooltip>
          {isNotSmallerScreen ? (
            <Text mt="2" color="white">
              Angular
            </Text>
          ) : null}
        </Box>
        <Spacer />
        <Box display="flex" flexDirection="column" alignItems="center">
          <Tooltip label="OpenCV" isDisabled={isNotSmallerScreen}>
            <IconButton
              variant="solid"
              colorScheme="gray"
              color=""
              icon={<SiOpencv />}
              isRound="true"
              size="lg"
              fontSize="x-large"
            />
          </Tooltip>
          {isNotSmallerScreen ? (
            <Text mt="2" color="white">
              OpenCV
            </Text>
          ) : null}
        </Box>
        <Spacer />
        <Box display="flex" flexDirection="column" alignItems="center">
          <Tooltip label="Deep Learning" isDisabled={isNotSmallerScreen}>
            <IconButton
              variant="solid"
              colorScheme="gray"
              color="#FF9100"
              icon={<SiTensorflow />}
              isRound="true"
              size="lg"
              fontSize="x-large"
            />
          </Tooltip>
          {isNotSmallerScreen ? (
            <Text mt="2" color="white">
              DL
            </Text>
          ) : null}
        </Box>
      </Flex>
    </Stack>
  );
}

export default Header;
