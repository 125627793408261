import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  Icon,
  Link,
  List,
  ListItem,
  ListIcon,
  Divider,
  Box,
  Center,
  Tooltip,
  Flex,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { SiGithub, SiLinkedin } from "react-icons/si";
import { GoLinkExternal } from "react-icons/go";
import { MdSettings } from "react-icons/md";
import { AiFillStar } from "react-icons/ai";
import ReactPlayer from "react-player/youtube";
import myModalObj from "./modalObj";

function ModalComponent({ canShow, updateModalState, props }) {
  // const [isNotSmallerScreen] = useMediaQuery(
  //   "(min-width:815px) and (min-height:860px)"
  // );
  const [modalObj, setModalObj] = useState({});

  useEffect(() => {
    const modalObjVariable = myModalObj();
    setModalObj(modalObjVariable[props.title]);
  }, [props]);

  if (modalObj && Object.keys(modalObj).length > 0) {
    return (
      <Modal
        closeOnOverlayClick={true}
        isOpen={canShow}
        onClose={updateModalState}
        motionPreset="scale"
        size="2xl"
        trapFocus={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize="2xl">{props.title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {modalObj.note && (
              <Box>
                <Flex justifyContent="center">
                  <Icon
                    as={AiFillStar}
                    alignSelf="center"
                    color="gold"
                    fontSize="xl"
                  ></Icon>
                  <Text ml="3">{modalObj.note}</Text>
                </Flex>
                <Divider my={4} />
              </Box>
            )}
            <Box mt={modalObj.note ? "7" : "0"}>
              <ReactPlayer
                playing={canShow}
                url={modalObj.youtube}
                width="100%"
                light={props.light}
                muted
                controls
              />
              <Center as="i" mt="2" fontSize="sm">
                Video Demonstration
              </Center>
            </Box>
            <Divider mt="4" />
            <Text mt="7">{modalObj.title}</Text>
            <Divider mt="7" />
            <Text mt="7">Technologies employed:</Text>
            <List spacing={1} mt="2">
              {modalObj.techArray.map((ele) => (
                <ListItem>
                  <ListIcon as={MdSettings} color="green.500" />
                  {ele}
                </ListItem>
              ))}
            </List>
          </ModalBody>

          <ModalFooter>
            {modalObj.hosting.length > 0 && (
              <Tooltip label="Hosting" openDelay={500}>
                <Link href={modalObj.hosting} isExternal>
                  <Button variant="solid" mr="2" colorScheme="purple">
                    <Icon as={GoLinkExternal}></Icon>
                  </Button>
                </Link>
              </Tooltip>
            )}
            {modalObj.github.length > 0 && (
              <Tooltip label="GitHub" openDelay={500}>
                <Link href={modalObj.github} isExternal>
                  <Button variant="solid" mr="2" colorScheme="green">
                    <Icon as={SiGithub}></Icon>
                  </Button>
                </Link>
              </Tooltip>
            )}
            {modalObj.linkedin.length > 0 && (
              <Tooltip label="LinkedIn" openDelay={500}>
                <Link href={modalObj.linkedin} isExternal>
                  <Button variant="solid" mr="2" colorScheme="blue">
                    <Icon as={SiLinkedin}></Icon>
                  </Button>
                </Link>
              </Tooltip>
            )}
            <Button colorScheme="red" mr={3} onClick={updateModalState}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  } else {
    return null;
  }
}

export default ModalComponent;
